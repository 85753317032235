export enum IconEnum {

    Bell = 'icon icon--bell',

    Mail = 'icon icon--mail',

    MailOpen = 'icon icon--mailopen',

    Book = 'icon icon--book',

    BookClose = 'icon icon--book2',

    BookAnswers = 'icon icon--bookAnswers',

    Redo = 'icon icon--redo2',

    Unit = 'icon icon--unit',

    Check = 'icon icon--check',

    Trophy = 'icon icon--trophy',

    TrophyBasic = 'icon icon--trophybasic',

    Student = 'icon icon--scan',

    Users = 'icon icon--users',

    UsersInactive = 'icon icon--groepinactief',

    User = 'icon icon--user',

    Cross = 'icon icon--cross',

    ParagraphLeft = 'icon icon--paragraph-left',

    Video = 'icon icon--video',

    Audio = 'icon icon--audio',

    Image = 'icon icon--image',

    Link = 'icon icon--link',

    Document = 'icon icon--other',

    Spreadsheet = 'icon icon--spreadsheet',

    Presentation = 'icon icon--presentation',

    Html = 'icon icon--html',

    Other = 'icon icon--document',

    Teacher = 'icon icon--docent',

    Message = 'icon icon--message',

    TeachingMaterial = 'icon icon--LesmateriaalEdit',

    TeacherMaterial = 'icon icon--LesmateriaalPlus',

    TeacherPageD = 'icon icon--docentmateriaal_V2',

    ActivateLicense = 'icon icon--LesmateriaalPlus',

    Drawer = 'icon icon--drawer',

    StarEmpty = 'icon icon--star-empty',

    StarFull = 'icon icon--star-full',

    TeacherInfo = 'icon icon--teacherInfo',

    QuotesLeft = 'icon icon--quotes-left',

    Article = 'icon icon--newspaper',

    Info = 'icon icon--info',

    Flag = 'icon icon--flag',

    Question = 'icon icon--question',

    QuestionMark = 'icon icon--questionmark',

    Global = 'icon icon--sphere',

    Attachment = 'icon icon--attachment',

    Pdf = 'icon icon--file-pdf2',

    Search = 'icon icon--search',

    Enlarge = 'icon icon--zoom-in',

    ZoomImage = 'icon icon--zoom-in',

    Drillster = 'icon icon--drillster',

    Ebook = 'icon icon--ebook',

    Add = 'icon icon--add',

    Edit = 'icon icon--edit',

    Refresh = 'icon icon--loop2',

    Chevron = 'icon icon--next',

    SortAz = 'icon icon--SortAZ',

    SortZa = 'icon icon--SortZA',

    Bin = 'icon icon--bin2',

    Map = 'icon icon--folder',

    Options = 'icon icon--options',

    Pause = 'icon icon--pause',

    Play = 'icon icon--play2',

    Warning = 'icon icon--warning2',

    WarningFull = 'icon icon--warning',

    ArrowLeft = 'icon icon--arrow-left2',

    ArrowRight = 'icon icon--arrow-right2',

    Bullseye = 'icon icon--bullseye',

    Circle = 'icon icon--circle',

    Eye = 'icon icon--eye',

    Binoculars = 'icon icon--binoculars',

    HomeWork = 'icon icon--homework',

    Reading = 'icon icon--reading',

    BookOpenReader = 'icon icon--book-open-reader',

    BookReading = 'icon icon--reading',

    School = 'icon icon--school',

    Trash = 'icon icon--delete',

    Send = 'icon icon--paper-plane',

    Bubble = 'icon icon--bubble2',

    Download = 'icon icon--download3',

    Excel = 'icon icon--file-excel',

    Legend = 'icon icon--list',

    TeacherTie = 'icon icon--user-tie',

    Case = 'icon icon--Casus',

    Print = 'icon icon--printer',

    Note = 'icon icon--note',

    HourGlass = 'icon icon--hour-glass',

    RoadMap = 'icon icon--roadmap',

    HomeRegular = 'icon icon--home-regular',

    Goals = 'icon icon--target',

    Verdieping = 'icon icon--verdieping',

    Definition = 'icon icon--definition',

    Artikel = 'icon icon--quotation',

    CheckCircle = 'icon icon--document-taskplan'
}
