import {EventEmitter, inject, Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {ToggleAssignmentAnswersInterface} from '@shared/modules/content/services/assignment/assignment.service';
import {AssignmentInterface} from '@shared/interfaces/assignment.interface';
import {RoleEnum} from '@core/security/enum/role.enum';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {Store} from '@ngrx/store';
import {LibrarySelectors} from '@store/library';
import {AssignmentActions} from '@store/assignment';

export interface DocumentAnswerStateInterface {
    documentId: string;
    showAnswerModel: boolean;
    answersDisabled: boolean;
    readOnlyState: boolean;
}

@Injectable()
export class AssignmentAnswersStateService {
    #store = inject(Store);

    #authorizationService = inject(AuthorizationService);

    #stateChangedEventEmitter: EventEmitter<Readonly<DocumentAnswerStateInterface>> = new EventEmitter();

    #states: Map<string, DocumentAnswerStateInterface> = new Map();

    #selfExamine: boolean = false;

    constructor() {
        this.#store.select(LibrarySelectors.selectBookSelfExamine).subscribe(selfExamine => this.#selfExamine = selfExamine);
    }

    remove(assignment: AssignmentInterface): void {
        if (assignment && assignment.dpsId === undefined) {
            return;
        }

        this.#states.delete(assignment.dpsId);
        this.#store.dispatch(AssignmentActions.remove(assignment));
    }

    get(assignment: AssignmentInterface): DocumentAnswerStateInterface | undefined {
        return this.#states.get(assignment.dpsId);
    }

    set(assignment: AssignmentInterface): void {
        const id: string = assignment.dpsId;
        const state: DocumentAnswerStateInterface = this.#getOrCreateState(id, true);

        state.showAnswerModel = ((this.#selfExamine || this.#authorizationService.isGranted(RoleEnum.RoleTeacher)) && null !== assignment.session);
        state.answersDisabled = assignment.inputDisabled;

        this.#states.set(id, state);
        this.#emit(state);
    }

    registerStateChangeEventListener(generatorOrNext?: (state: Readonly<DocumentAnswerStateInterface>) => void, error?: Function, complete?: Function): Subscription {
        return this.#stateChangedEventEmitter.subscribe(generatorOrNext, error, complete);
    }

    toggleAnswerModel(assignment: AssignmentInterface, value: boolean): boolean {
        return value ? this.showAnswerModel(assignment) : this.hideAnswerModel(assignment);
    }

    showAnswerModel(assignment: AssignmentInterface): boolean {
        const state: DocumentAnswerStateInterface = this.#getOrCreateState(assignment.dpsId);
        const answersDisabled: boolean = state.showAnswerModel = true;

        this.#emit(state);

        return answersDisabled;
    }

    hideAnswerModel(assignment: AssignmentInterface): boolean {
        const state: DocumentAnswerStateInterface = this.#getOrCreateState(assignment.dpsId);
        const answersDisabled: boolean = state.showAnswerModel = false;

        this.#emit(state);

        return answersDisabled;
    }

    showDisabledAnswers(assignment: AssignmentInterface): void {
        const state = this.#getOrCreateState(assignment.dpsId);

        state.answersDisabled = true;

        this.#emit(state);
    }

    hideDisabledAnswers(assignment: AssignmentInterface): void {
        const state = this.#getOrCreateState(assignment.dpsId);

        state.answersDisabled = false;

        this.#emit(state);
    }

    isReadOnly(assignment: AssignmentInterface): void {
        const state = this.#getOrCreateState(assignment.dpsId);

        state.readOnlyState = true;

        this.#emit(state);
    }

    isNotReadOnly(assignment: AssignmentInterface): void {
        const state = this.#getOrCreateState(assignment.dpsId);

        state.readOnlyState = false;

        this.#emit(state);
    }

    #emit(state: DocumentAnswerStateInterface): void {
        this.#stateChangedEventEmitter.emit(state);
    }

    #handleToggleAnswers(toggleAssignmentAnswers: ToggleAssignmentAnswersInterface): void {
        toggleAssignmentAnswers.value ? this.showAnswerModel(toggleAssignmentAnswers.assignment) : this.hideAnswerModel(toggleAssignmentAnswers.assignment);
    }

    #getOrCreateState(documentId: string, reset: boolean = false): DocumentAnswerStateInterface {
        const componentState: DocumentAnswerStateInterface = (!reset ? this.#states.get(documentId) : undefined) ?? {documentId, answersDisabled: false, readOnlyState: false, showAnswerModel: false};

        if (!this.#states.has(documentId)) {
            this.#states.set(documentId, componentState);
        }

        return componentState;
    }
}
